"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isGradContact = exports.isGradPupil = exports.ContactStatus = void 0;
var ContactStatus;
(function (ContactStatus) {
    ContactStatus["DELIVERY_FAILED"] = "DELIVERY_FAILED";
    ContactStatus["MESSAGE_SENT"] = "MESSAGE_SENT";
})(ContactStatus = exports.ContactStatus || (exports.ContactStatus = {}));
function isGradPupil(val) {
    return typeof val.studentId !== 'undefined';
}
exports.isGradPupil = isGradPupil;
function isGradContact(val) {
    return typeof val.email !== 'undefined';
}
exports.isGradContact = isGradContact;
