"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Job = void 0;
const helpers_1 = require("../../helpers");
class JobCollection extends helpers_1.Collection {
    byAimsJobNumber(accountCode, jobCode) {
        return new helpers_1.QueryResult(super.collection, super.where('account.code', '==', accountCode), super.where('aimsJobNumber', '==', jobCode));
    }
}
class JobDocument extends helpers_1.Model {
}
class JobWithData extends JobDocument {
}
exports.Job = new JobCollection('jobs', JobDocument, JobWithData);
