"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityType = void 0;
var ActivityType;
(function (ActivityType) {
    ActivityType["ACCOUNT"] = "Account";
    ActivityType["BOOKING"] = "Booking";
    ActivityType["AUTH"] = "Auth";
    ActivityType["CONTACT"] = "Contact";
    ActivityType["DISCOUNTADDED"] = "DiscountAdded";
    ActivityType["DISCOUNTCLAIMED"] = "DiscountClaimed";
    ActivityType["DISCOUNTCLAIMREFUNDED"] = "DiscountClaimRefunded";
    ActivityType["DISCOUNTEXISTS"] = "DiscountExists";
    ActivityType["DISCOUNTINVALIDATED"] = "DiscountInvalidated";
    ActivityType["IMAGE"] = "Image";
    ActivityType["JOB"] = "Job";
    ActivityType["PUPIL"] = "Pupil";
    ActivityType["PORTALACTION"] = "PortalAction";
    ActivityType["GRADACTION"] = "GradAction";
    ActivityType["CUSTOMERPROFILE"] = "CustomerProfile";
    ActivityType["SETTINGSUPDATE"] = "SettingsUpdate";
    ActivityType["RELATIONSHIP"] = "Relationship";
})(ActivityType = exports.ActivityType || (exports.ActivityType = {}));
