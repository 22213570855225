"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Account = void 0;
const firestore_1 = require("firebase/firestore");
const helpers_1 = require("../../helpers");
class AccountCollection extends helpers_1.Collection {
}
class AccountDocument extends helpers_1.Model {
    async addVenue(venue) {
        return await this.updateDocument({ venues: (0, firestore_1.arrayUnion)(venue) });
    }
}
class AccountWithData extends AccountDocument {
}
exports.Account = new AccountCollection('accounts', AccountDocument, AccountWithData);
