"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageEditingActiveStatuses = exports.isTypeMultiValue = exports.multiValueEditTypes = exports.editPriorityValue = exports.EditJobSource = exports.EditJobPriority = exports.ActionCategory = exports.EditJobStatus = exports.EditDataType = void 0;
__exportStar(require("./download"), exports);
var EditDataType;
(function (EditDataType) {
    EditDataType["LONGREFS"] = "Image Long Refs";
    EditDataType["REFS"] = "Image Refs";
    EditDataType["AIMSJOB"] = "Aims Job Number";
})(EditDataType = exports.EditDataType || (exports.EditDataType = {}));
var EditJobStatus;
(function (EditJobStatus) {
    EditJobStatus["HOLD"] = "On Hold";
    EditJobStatus["CHECKING_IMAGES"] = "Checking Images";
    EditJobStatus["UNASSIGNED"] = "Unassigned";
    EditJobStatus["ASSGINED"] = "Assigned";
    EditJobStatus["IN_PROGRESS"] = "In Progress";
    EditJobStatus["ERROR"] = "Errored";
    EditJobStatus["COMPLETE"] = "Complete";
})(EditJobStatus = exports.EditJobStatus || (exports.EditJobStatus = {}));
var ActionCategory;
(function (ActionCategory) {
    ActionCategory["EXPOSURE"] = "Exposure";
    ActionCategory["DOWNLOAD"] = "Download";
    ActionCategory["COPYRIGHT_DWN"] = "Copyright Download";
    ActionCategory["HAZE"] = "Haze";
    ActionCategory["BACKGROUND"] = "Background Repair";
    ActionCategory["SNOTTY"] = "Snotty Nose";
    ActionCategory["REFLECTION"] = "Reflection";
    ActionCategory["HEAD_SWAPS"] = "Head Swaps";
    ActionCategory["EYE_SWAPS"] = "Eye Swaps";
    ActionCategory["BARCUTOFF"] = "Cut Off";
    ActionCategory["SHARPENING"] = "Sharpening";
    ActionCategory["FRAMING"] = "Framing/Crop/Resize";
    ActionCategory["CENTER"] = "Center UP/DOWN";
    ActionCategory["SHINE"] = "Shine";
    ActionCategory["FOOD"] = "Food";
    ActionCategory["FALL_OFF"] = "Fall off";
    ActionCategory["TRIP"] = "Triptych";
    ActionCategory["ROT"] = "Rotation";
    ActionCategory["WEBSITE"] = "Website";
    ActionCategory["COMPOSITE"] = "Composite";
    ActionCategory["Bespoke"] = "Bespoke Composite";
    ActionCategory["REFRACTION"] = "Refraction";
    ActionCategory["RED_EYE"] = "Red Eye";
    ActionCategory["UNDERWARE"] = "Underwear Showing";
    ActionCategory["UNIFORM"] = "Tidy Uniform";
    ActionCategory["COSMETIC"] = "Cosmetic";
    ActionCategory["BESPOKE"] = "Bespoke";
    ActionCategory["ARTWORK_UPLOAD"] = "Artwork Upload";
    ActionCategory["SOME_ONE_LOVE"] = "Someone You Love";
    ActionCategory["ARTWORK_AMEND"] = "Artwork Amendment";
    ActionCategory["STUDIO_GROUP"] = "Studio Group";
    ActionCategory["CMOS"] = "CMOS";
    ActionCategory["MG"] = "MG";
    ActionCategory["OTHER"] = "Other";
    ActionCategory["NONE"] = "None";
})(ActionCategory = exports.ActionCategory || (exports.ActionCategory = {}));
var EditJobPriority;
(function (EditJobPriority) {
    EditJobPriority["URGENT"] = "Urgent";
    EditJobPriority["PRIORITY"] = "High Priority";
    EditJobPriority["NORMAL"] = "Normal";
})(EditJobPriority = exports.EditJobPriority || (exports.EditJobPriority = {}));
var EditJobSource;
(function (EditJobSource) {
    EditJobSource["CUSTOMER_SERVICE"] = "Customer Service";
    EditJobSource["DIRECT_ORDERES"] = "Direct Orders";
    EditJobSource["ORDER_ENTRIES"] = "Order Entry";
    EditJobSource["SALES"] = "Sales";
    EditJobSource["SANDHURST"] = "Sandhurst";
    EditJobSource["MILITARIY"] = "Tempest Military";
    EditJobSource["PRODUCTION"] = "Production";
    EditJobSource["PIRBRIGHT"] = "Pirbright";
    EditJobSource["GRANDTHAM"] = "Grantham";
    EditJobSource["PACKING"] = "Packing";
    EditJobSource["PREMIER"] = "PREMIER";
    EditJobSource["PRINTING"] = "Printing";
    EditJobSource["QC"] = "QC";
    EditJobSource["AUTOMATIC"] = "Automatic";
    EditJobSource["OTHER"] = "Other";
})(EditJobSource = exports.EditJobSource || (exports.EditJobSource = {}));
function editPriorityValue(p) {
    const priority = typeof p !== 'string' ? p.priority : p;
    if (priority === EditJobPriority.URGENT)
        return 3;
    if (priority === EditJobPriority.PRIORITY)
        return 2;
    return 1;
}
exports.editPriorityValue = editPriorityValue;
exports.multiValueEditTypes = [
    EditDataType.LONGREFS,
    EditDataType.REFS
];
function isTypeMultiValue(data) {
    if (typeof data === 'string') {
        return !!exports.multiValueEditTypes.find(a => a === data);
    }
    return !!exports.multiValueEditTypes.find(a => a === data.dataType);
}
exports.isTypeMultiValue = isTypeMultiValue;
exports.ImageEditingActiveStatuses = [EditJobStatus.UNASSIGNED, EditJobStatus.HOLD, EditJobStatus.CHECKING_IMAGES, EditJobStatus.IN_PROGRESS, EditJobStatus.ASSGINED, EditJobStatus.ERROR];
