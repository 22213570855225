"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryResult = exports.WithDocumentResult = void 0;
const firestore_1 = require("firebase/firestore");
const errors_1 = require("./errors");
const observer_1 = require("./observer");
function WithDocumentResult(Base, doc) {
    return class DocumentResultMixin extends (0, observer_1.WithDocumentObserver)(Base, doc) {
        async single() {
            const snapshot = await (0, firestore_1.getDoc)(doc);
            if (!snapshot.exists()) {
                throw new errors_1.DocumentNotExistError();
            }
            return snapshot.data();
        }
        async singleOrNull() {
            const snapshot = await (0, firestore_1.getDoc)(doc);
            if (!snapshot.exists()) {
                return null;
            }
            return snapshot.data();
        }
        async update(data) {
            await (0, firestore_1.updateDoc)(doc, data);
        }
    };
}
exports.WithDocumentResult = WithDocumentResult;
class QueryResult extends observer_1.QueryObserver {
    async single() {
        const snapshot = await (0, firestore_1.getDocs)((0, firestore_1.query)(this.query, (0, firestore_1.limit)(1)));
        if (snapshot.size === 0) {
            throw new errors_1.DocumentNotExistError();
        }
        return snapshot.docs[0].data();
    }
    async singleOrNull() {
        const snapshot = await (0, firestore_1.getDocs)((0, firestore_1.query)(this.query, (0, firestore_1.limit)(1)));
        if (snapshot.size === 0) {
            return null;
        }
        return snapshot.docs[0].data();
    }
    async multiple() {
        const snapshot = await (0, firestore_1.getDocs)(this.query);
        return snapshot.docs.map(x => x.data());
    }
    static merge(collection, ...queryResults) {
        return new QueryResult(collection, ...queryResults.flatMap(x => x.queryConstraints));
    }
}
exports.QueryResult = QueryResult;
