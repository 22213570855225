"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebOrderOrigin = exports.PaymentStatus = void 0;
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["AUTHORISED"] = "AUTHORISED";
    PaymentStatus["CANCELLED"] = "CANCELLED";
    PaymentStatus["AUTHORISATION"] = "AUTHORISATION";
    PaymentStatus["ERROR"] = "ERROR";
    PaymentStatus["FAIL"] = "FAIL";
    PaymentStatus["PAID"] = "PAID";
    PaymentStatus["PENDING"] = "PENDING";
    PaymentStatus["REFUND"] = "REFUND";
    PaymentStatus["REFUND_WITH_DATA"] = "REFUND_WITH_DATA";
    PaymentStatus["REFUSED"] = "REFUSED";
    // The following are potential Cybersource responses
    PaymentStatus["AUTHORIZED"] = "AUTHORIZED";
    PaymentStatus["PARTIAL_AUTHORIZED"] = "PARTIAL_AUTHORIZED";
    PaymentStatus["REVERSED"] = "REVERSED";
    PaymentStatus["VOIDED"] = "VOIDED";
    PaymentStatus["ACCEPTED"] = "ACCEPTED";
    PaymentStatus["REJECTED"] = "REJECTED";
    PaymentStatus["COMPLETED"] = "COMPLETED";
    PaymentStatus["FAILED"] = "FAILED";
    PaymentStatus["PENDING_REVIEW"] = "PENDING_REVIEW";
    PaymentStatus["CHALLENGE"] = "CHALLENGE";
    PaymentStatus["AUTHENTICATION_SUCCESSFUL"] = "AUTHENTICATION_SUCCESSFUL";
    PaymentStatus["AUTHENTICATION_FAILED"] = "AUTHENTICATION_FAILED";
    PaymentStatus["AUTHORIZED_PENDING_REVIEW"] = "AUTHORIZED_PENDING_REVIEW";
    PaymentStatus["AUTHORIZED_RISKDECLINED"] = "AUTHORIZED_RISK_DECLINED";
    PaymentStatus["DECLINED"] = "DECLINED";
    PaymentStatus["PENDING_AUTHENTICATION"] = "PENDING_AUTHENTICATION";
    PaymentStatus["INVALID_REQUEST"] = "INVALID_REQUEST";
    PaymentStatus["SERVER_ERROR"] = "SERVER_ERROR";
})(PaymentStatus = exports.PaymentStatus || (exports.PaymentStatus = {}));
var WebOrderOrigin;
(function (WebOrderOrigin) {
    WebOrderOrigin["SCHOOLS"] = "Schools web order";
    WebOrderOrigin["GRADS"] = "Grads web order";
    WebOrderOrigin["EVENTS"] = "Events web order";
    WebOrderOrigin["GROUPS"] = "Groups web order";
    WebOrderOrigin["TICKETING"] = "Ticketing Issue";
    WebOrderOrigin["DOFE"] = "DofE web order";
    WebOrderOrigin["BOOKINGS"] = "Bookings web order";
    WebOrderOrigin["GOWNS"] = "Gowns web order";
    WebOrderOrigin["NONESET"] = " No value set";
})(WebOrderOrigin = exports.WebOrderOrigin || (exports.WebOrderOrigin = {}));
