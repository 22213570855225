"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganisationType = void 0;
var OrganisationType;
(function (OrganisationType) {
    OrganisationType["ROOT"] = "root";
    OrganisationType["MARKET"] = "market";
    OrganisationType["AREA"] = "area";
    OrganisationType["TERRITORY"] = "territory";
})(OrganisationType = exports.OrganisationType || (exports.OrganisationType = {}));
