"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactDetail = void 0;
class ContactDetail {
    constructor(email, mobile, pupilContactId) {
        this.email = email;
        this.mobile = mobile;
        this.pupilContactId = pupilContactId;
    }
    equal(other) {
        const emailMatches = this.email === other.email;
        const mobileMatches = this.mobile === other.mobile;
        return emailMatches && mobileMatches;
    }
    static fromPupilContact(contact) {
        var _a, _b, _c, _d;
        const emailRaw = (_b = (_a = contact.email) !== null && _a !== void 0 ? _a : contact.parentEmail) !== null && _b !== void 0 ? _b : null;
        const mobileRaw = (_d = (_c = contact.mobile) !== null && _c !== void 0 ? _c : contact.parentMobile) !== null && _d !== void 0 ? _d : null;
        try {
            return this.create(emailRaw, mobileRaw, contact.pupilContactId);
        }
        catch (e) {
            console.error(`Cannot create ContactDetail with values ${JSON.stringify([emailRaw, mobileRaw, contact.pupilContactId])}`);
            throw e;
        }
    }
    static create(email, mobile, pupilContactId) {
        return new ContactDetail(this.sanitize(email), this.sanitize(mobile), pupilContactId);
    }
    static sanitize(value) {
        if (value == null)
            return null;
        if (typeof value === 'string') {
            const trimmed = value.trim();
            return trimmed.length > 0 ? trimmed : null;
        }
        return null;
    }
}
exports.ContactDetail = ContactDetail;
