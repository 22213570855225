"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapCache = exports.ArrayCache = exports.DELETE = exports.UPDATE = exports.CREATE = void 0;
const lodash_1 = require("lodash");
exports.CREATE = Symbol('Create element on Cache');
exports.UPDATE = Symbol('Update element on Cache');
exports.DELETE = Symbol('Delete element on Cache');
class ArrayCache {
    constructor(cache, defaultMetadata) {
        this.cache = cache;
        this.defaultMetadata = defaultMetadata;
        this.defaultMetadata = (0, lodash_1.cloneDeep)(this.defaultMetadata);
    }
    has(id) {
        return this.cache.some(x => x.model.DocumentID === id);
    }
    get(id) {
        return this.cache.find(x => x.model.DocumentID === id);
    }
    get size() {
        return this.cache.length;
    }
    getIndex(id) {
        const index = this.cache.findIndex(x => x.model.DocumentID === id);
        return index >= 0 ? index : undefined;
    }
    [exports.CREATE](id, model) {
        if (this.has(id)) {
            throw new Error(`Document with id ${id} already exists`);
        }
        const item = { model, metadata: (0, lodash_1.cloneDeep)(this.defaultMetadata), updateMetadata: (metadata) => this.setMetadata(id, metadata) };
        this.cache.push(item);
        return item;
    }
    [exports.UPDATE](id, item) {
        const currentIndex = this.getIndex(id);
        if (currentIndex === undefined) {
            throw new Error(`Document ${id} does not exist`);
        }
        const currentItem = this.cache[currentIndex];
        const newItem = { ...currentItem, ...item };
        this.cache.splice(currentIndex, 1, newItem);
        return newItem;
    }
    [exports.DELETE](id) {
        const currentIndex = this.getIndex(id);
        if (currentIndex === undefined) {
            return false;
        }
        this.cache.splice(currentIndex, 1);
        return true;
    }
    setMetadata(id, metadata) {
        this[exports.UPDATE](id, { metadata });
    }
    forEach(callbackfn) {
        return this.cache.forEach(callbackfn);
    }
}
exports.ArrayCache = ArrayCache;
class MapCache {
    constructor(cache, defaultMetadata) {
        this.cache = cache;
        this.defaultMetadata = defaultMetadata;
        this.defaultMetadata = (0, lodash_1.cloneDeep)(this.defaultMetadata);
    }
    has(id) {
        return this.cache.has(id);
    }
    get(id) {
        return this.cache.get(id);
    }
    get size() {
        return this.cache.size;
    }
    [exports.CREATE](id, document) {
        const item = { model: document, metadata: (0, lodash_1.cloneDeep)(this.defaultMetadata), updateMetadata: (metadata) => this.setMetadata(id, metadata) };
        this.cache.set(id, item);
        return item;
    }
    [exports.UPDATE](id, item) {
        const currentItem = this.cache.get(id);
        if (currentItem === undefined) {
            throw new Error(`Document ${id} does not exist`);
        }
        const newItem = { ...currentItem, ...item };
        this.cache.set(id, newItem);
        return newItem;
    }
    [exports.DELETE](id) {
        return this.cache.delete(id);
    }
    setMetadata(id, metadata) {
        this[exports.UPDATE](id, { metadata });
    }
    forEach(callbackfn) {
        return this.cache.forEach(callbackfn);
    }
}
exports.MapCache = MapCache;
