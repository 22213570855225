"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Image = void 0;
const accounts_models_1 = require("@ht-lib/accounts-models");
const firestore_1 = require("firebase/firestore");
const helpers_1 = require("../../helpers");
const longRefRegex = /^[A-F0-9]{32}$/;
class ImageCollection extends helpers_1.Collection {
    async addImage(longRef, data) {
        if (!longRefRegex.test(longRef)) {
            throw new helpers_1.InvalidLongRefIdError(longRef);
        }
        const existing = await this.byId(longRef).singleOrNull();
        if (existing !== null) {
            throw new helpers_1.DocumentAlreadyExistsError(longRef);
        }
        const docRef = (0, firestore_1.doc)(this.collection, longRef);
        await (0, firestore_1.setDoc)(docRef, data);
        return new this.DocumentWithData(docRef, data);
    }
    byAimsJob(accountCode, jobCode, onlyFlagged = false) {
        const queryConstraints = [
            this.where('accountCode', '==', accountCode.toUpperCase()),
            this.where('jobCode', '==', jobCode)
        ];
        if (onlyFlagged) {
            queryConstraints.push(this.where('status', '==', accounts_models_1.ImageStatus.FLAGGED));
        }
        return new helpers_1.QueryResult(this.collection, ...queryConstraints);
    }
    byJobId(jobId, onlyFlagged = false) {
        const queryConstraints = [
            this.where('jobId', '==', jobId)
        ];
        if (onlyFlagged) {
            queryConstraints.push(this.where('status', '==', accounts_models_1.ImageStatus.FLAGGED));
        }
        return new helpers_1.QueryResult(this.collection, ...queryConstraints);
    }
    byUploaderId(uploaderId) {
        return new helpers_1.QueryResult(this.collection, Array.isArray(uploaderId)
            ? this.where('uploaderId', 'in', uploaderId)
            : this.where('uploaderId', '==', uploaderId));
    }
    byJobIdWhereChildProtection(jobId) {
        return new helpers_1.QueryResult(this.collection, this.where('jobId', '==', jobId), this.where('childProtectionIssue', '==', true));
    }
    byRef(market, ref) {
        return new helpers_1.QueryResult(this.collection, this.where('market', '==', market), this.where('ref', '==', ref.toUpperCase()), this.orderBy('nexusId', 'desc'));
    }
    async batchSetFromData(imageModels) {
        return await (0, helpers_1.batchSetMerge)(this.collection, (0, helpers_1.dataModelToDocument)(imageModels, 'longRef'));
    }
    async batchUpdateFromData(imageModels) {
        return await (0, helpers_1.batchUpdate)(this.collection, (0, helpers_1.dataModelToDocument)(imageModels, 'longRef'));
    }
    query(...queries) {
        return helpers_1.QueryResult.merge(this.collection, ...queries);
    }
}
class ImageDocument extends helpers_1.Model {
    async setUploaded(uploaded = true) {
        return await this.updateDocument({ uploaded });
    }
}
class ImageWithData extends ImageDocument {
}
exports.Image = new ImageCollection('images', ImageDocument, ImageWithData);
