"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapCache = exports.ArrayCache = exports.initializeCompat = exports.initialize = exports.getDefaultAuth = void 0;
var firebase_1 = require("./firebase");
Object.defineProperty(exports, "getDefaultAuth", { enumerable: true, get: function () { return firebase_1.getDefaultAuth; } });
Object.defineProperty(exports, "initialize", { enumerable: true, get: function () { return firebase_1.initialize; } });
Object.defineProperty(exports, "initializeCompat", { enumerable: true, get: function () { return firebase_1.initializeCompat; } });
var cache_1 = require("./helpers/cache");
Object.defineProperty(exports, "ArrayCache", { enumerable: true, get: function () { return cache_1.ArrayCache; } });
Object.defineProperty(exports, "MapCache", { enumerable: true, get: function () { return cache_1.MapCache; } });
__exportStar(require("./helpers/observer"), exports);
__exportStar(require("./helpers/result"), exports);
__exportStar(require("./models"), exports);
__exportStar(require("./storage"), exports);
