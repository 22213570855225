"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountActivityAction = exports.AccountStatus = void 0;
var AccountStatus;
(function (AccountStatus) {
    AccountStatus["ARCHIVED"] = "archived";
    AccountStatus["CUSTOMER"] = "customer";
    AccountStatus["GOLD"] = "gold";
    AccountStatus["MOST_WANTED"] = "most_wanted";
    AccountStatus["NON_CUSTOMER"] = "non_customer";
    AccountStatus["NON_VIABLE"] = "non_viable";
    AccountStatus["PLATINUM"] = "platinum";
    AccountStatus["SILVER"] = "silver";
})(AccountStatus = exports.AccountStatus || (exports.AccountStatus = {}));
var AccountActivityAction;
(function (AccountActivityAction) {
    AccountActivityAction["CALL"] = "call";
    AccountActivityAction["EMAIL"] = "email";
    AccountActivityAction["MAILSHOT"] = "mailshot";
    AccountActivityAction["CANVASSING"] = "canvassing";
    AccountActivityAction["GAIN"] = "gain";
    AccountActivityAction["LOSS"] = "lost";
    // Deprecated
    AccountActivityAction["OFSTED_MUG_FOLLOWUP"] = "OFSTED";
    // Deprecated
    AccountActivityAction["OFSTED_MUG_SENT"] = "OFSTEDMug";
    AccountActivityAction["OTHER"] = "other";
    AccountActivityAction["VISIT"] = "visit";
})(AccountActivityAction = exports.AccountActivityAction || (exports.AccountActivityAction = {}));
