"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImagePrepPreset = void 0;
const firestore_1 = require("firebase/firestore");
const helpers_1 = require("../../helpers");
class ImagePrepPresetCollection extends helpers_1.Collection {
    listen(onNext, onError, onCompletion) {
        return (0, helpers_1.listenCollection)(this.collection, onNext, onError, onCompletion);
    }
    async addPreset(preset) {
        const ref = (0, firestore_1.doc)(this.collection);
        preset.id = ref.id;
        return await (0, firestore_1.setDoc)(ref, preset);
    }
}
class ImagePrepPresetDocument extends helpers_1.Model {
    async update(preset) {
        await this.updateDocument(preset);
    }
    async delete() {
        return await this.deleteDocument();
    }
}
class ImagePrepPresetWithData extends ImagePrepPresetDocument {
}
exports.ImagePrepPreset = new ImagePrepPresetCollection('imagePrepPresets', ImagePrepPresetDocument, ImagePrepPresetWithData);
