"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultPrepSettings = exports.ImageFileType = exports.DownloadStatus = void 0;
var DownloadStatus;
(function (DownloadStatus) {
    DownloadStatus["PREPARING"] = "Preparing";
    DownloadStatus["REQUESTED"] = "Requested";
    DownloadStatus["ERRORED"] = "Errored";
    DownloadStatus["FINISHED"] = "Finished";
})(DownloadStatus = exports.DownloadStatus || (exports.DownloadStatus = {}));
var ImageFileType;
(function (ImageFileType) {
    ImageFileType["JPG"] = "jpg";
    ImageFileType["BMP"] = "bmp";
})(ImageFileType = exports.ImageFileType || (exports.ImageFileType = {}));
function getDefaultPrepSettings() {
    return {
        x: 0,
        y: 0,
        adjust: true,
        nexuscrop: true,
        sharpen: false,
        needscc: true,
        watermark: false,
        keeptimestamp: true,
        xinch: 0,
        yinch: 0,
        watermarkopacity: 0.6,
        watermarkcolour: '#ffffff',
        filetype: ImageFileType.JPG,
        quality: 85
    };
}
exports.getDefaultPrepSettings = getDefaultPrepSettings;
