"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMarketFromStudioJob = void 0;
function getMarketFromStudioJob(s) {
    switch (s.job.jobTypeid) {
        case 8: {
            return 'G';
        }
        default: {
            return 'S';
        }
    }
}
exports.getMarketFromStudioJob = getMarketFromStudioJob;
