"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBucket = exports.getDefaultBucket = void 0;
const storage_1 = require("firebase/storage");
const firebase_1 = require("./firebase");
const DEFAULT = '[DEFAULT]';
const cache = new Map();
function getDefaultBucket() {
    return getBucket(DEFAULT);
}
exports.getDefaultBucket = getDefaultBucket;
function getBucket(name) {
    if (!cache.has(name)) {
        const storage = name === DEFAULT
            ? (0, firebase_1.getDefaultStorage)()
            : (0, storage_1.getStorage)((0, firebase_1.getActualApp)(), name);
        cache.set(name, new StorageBucket(storage));
    }
    return cache.get(name);
}
exports.getBucket = getBucket;
class StorageBucket {
    constructor(storage) {
        this.storage = storage;
    }
    async getMetadata(objectUrl) {
        return await (0, storage_1.getMetadata)((0, storage_1.ref)(this.storage, objectUrl));
    }
    async upload(objectUrl, data, metadata) {
        return await (0, storage_1.uploadBytes)((0, storage_1.ref)(this.storage, objectUrl), data, metadata);
    }
    uploadResumable(objectUrl, data, metadata) {
        return (0, storage_1.uploadBytesResumable)((0, storage_1.ref)(this.storage, objectUrl), data, metadata);
    }
    async getURL(objectUrl) {
        return await (0, storage_1.getDownloadURL)((0, storage_1.ref)(this.storage, objectUrl));
    }
}
