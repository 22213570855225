import Default from '@/layouts/Default/Default.vue'
import { RouteConfig } from 'vue-router'

export const routes: RouteConfig[] = [
  {
    path: '/',
    component: Default,
    children: [
      {
        path: '',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/Default.vue'),
        name: 'default'
      },
      {
        path: '/payment/:paymentId',
        component: async () => await import(/* webpackPrefetch: true */ '@/pages/Default.vue'),
        name: 'payment',
        props: route => ({ paymentId: route.params.paymentId })
      }
    ]
  },
  {
    path: '/401',
    name: '401',
    component: async () => await import(/* webpackPrefetch: true */ '@/pages/Error401.vue')
  },
  { // Always leave this as last one
    path: '*',
    component: async () => await import(/* webpackPrefetch: true */ '@/pages/Error404.vue')
  }
]

export default routes
