"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfile = void 0;
const helpers_1 = require("../../helpers");
class UserProfileCollection extends helpers_1.Collection {
    byPhotographerCode(photographerCode) {
        return new helpers_1.QueryResult(this.collection, this.where('photographerId', '==', photographerCode));
    }
}
class UserProfileDocument extends helpers_1.Model {
}
class UserProfileWithData extends UserProfileDocument {
}
exports.UserProfile = new UserProfileCollection('userProfiles', UserProfileDocument, UserProfileWithData);
