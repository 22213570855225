"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Collection_collection, _Collection_collectionName;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collection = void 0;
const firestore_1 = require("firebase/firestore");
const firebase_1 = require("../firebase");
const converters_1 = require("./converters");
const result_1 = require("./result");
class Collection {
    constructor(collectionName, Document, DocumentWithData) {
        _Collection_collection.set(this, void 0);
        _Collection_collectionName.set(this, void 0);
        __classPrivateFieldSet(this, _Collection_collectionName, collectionName, "f");
        this.Document = Document;
        this.DocumentWithData = DocumentWithData;
    }
    async addDocument(data) {
        if (data === null) {
            throw new Error('No data supplied');
        }
        const docRef = await (0, firestore_1.addDoc)(this.collection, data);
        return docRef.id;
    }
    byId(id) {
        const docRef = (0, firestore_1.doc)(this.collection, id);
        const WDR = (0, result_1.WithDocumentResult)(this.Document, docRef);
        return new WDR(docRef);
    }
    where(fieldPath, opStr, value) {
        return (0, firestore_1.where)(fieldPath, opStr, value);
    }
    orderBy(fieldPath, directionStr = 'asc') {
        return (0, firestore_1.orderBy)(fieldPath, directionStr);
    }
    get collection() {
        if (__classPrivateFieldGet(this, _Collection_collection, "f") === undefined) {
            __classPrivateFieldSet(this, _Collection_collection, getCollectionWithDefaultConverter(this.DocumentWithData, __classPrivateFieldGet(this, _Collection_collectionName, "f")), "f");
        }
        return __classPrivateFieldGet(this, _Collection_collection, "f");
    }
}
exports.Collection = Collection;
_Collection_collection = new WeakMap(), _Collection_collectionName = new WeakMap();
function getCollectionWithDefaultConverter(ConcreteModel, collectionName) {
    return (0, firestore_1.collection)((0, firebase_1.getApp)(), collectionName).withConverter((0, converters_1.firestoreDataConverter)(ConcreteModel));
}
