"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listenCollection = exports.QueryObserver = exports.WithDocumentObserver = void 0;
const firestore_1 = require("firebase/firestore");
const cache_1 = require("./cache");
function WithDocumentObserver(Base, ref) {
    return class DocumentObserverMixin extends Base /* implements IDocumentObserverMixin<T> */ {
        listen(onNext, onError, onCompletion) {
            return (0, firestore_1.onSnapshot)(ref, snapshot => {
                onNext(snapshot.data());
            }, onError, onCompletion);
        }
        listenWithCache(cache, onNext, onError, onCompletion) {
            const unsubscribe = (0, firestore_1.onSnapshot)(ref, snapshot => {
                if (snapshot.exists() && !cache.has(snapshot.id)) {
                    cache.set(snapshot.id, snapshot.data());
                }
                onNext();
            }, onError, onCompletion);
            return unsubscribe;
        }
    };
}
exports.WithDocumentObserver = WithDocumentObserver;
function isSnapshotListenerNext(value) {
    return 'onAddedOrModified' in value;
}
class QueryObserver {
    constructor(ref, ...queryConstraints) {
        this.ref = ref;
        this.queryConstraints = queryConstraints;
    }
    get query() {
        return (0, firestore_1.query)(this.ref, ...this.queryConstraints);
    }
    listen(onNext, onError, onCompletion) {
        const unsubscribe = (0, firestore_1.onSnapshot)(this.query, snapshot => onNext(snapshot.docs.map(x => x.data())), onError, onCompletion);
        return unsubscribe;
    }
    // public listenChangesMapCache<TMetadata = never> (defaultMetadata: TMetadata, onAdded: OnAddedCache<T, TMetadata>, onModified: OnModifiedCache<T, TMetadata>, onRemoved?: OnRemovedCache, onError?: (error: FirestoreError) => void, onCompletion?: () => void): ObservableCache<T, TMetadata> {
    listenChangesMapCache(options) {
        const cache = new cache_1.MapCache(new Map(), options.defaultMetadata);
        const unsubscribe = this.listenChangesWithCache(cache, options);
        return { cache, unsubscribe };
    }
    listenChangesWithCache(cache, options) {
        const { onRemoved, onError, onCompletion } = options;
        let onAdded;
        let onModified;
        if (isSnapshotListenerNext(options)) {
            onAdded = options.onAddedOrModified;
            onModified = options.onAddedOrModified;
        }
        else {
            onAdded = options.onAdded;
            onModified = options.onModified;
        }
        const unsubscribe = (0, firestore_1.onSnapshot)(this.query, snapshot => {
            snapshot
                .docChanges()
                .forEach(change => {
                const model = change.doc.data();
                if (change.type === 'added') {
                    const value = cache[cache_1.CREATE](change.doc.id, model);
                    onAdded === null || onAdded === void 0 ? void 0 : onAdded(value);
                }
                else if (change.type === 'modified') {
                    const value = cache[cache_1.UPDATE](change.doc.id, { model });
                    onModified === null || onModified === void 0 ? void 0 : onModified(value);
                }
                else if (change.type === 'removed') {
                    cache[cache_1.DELETE](change.doc.id);
                    onRemoved === null || onRemoved === void 0 ? void 0 : onRemoved(change.doc.id);
                }
            });
        }, onError, onCompletion);
        return unsubscribe;
    }
}
exports.QueryObserver = QueryObserver;
function listenCollection(ref, onNext, onError, onCompletion) {
    return (0, firestore_1.onSnapshot)(ref, snapshot => {
        onNext(snapshot.docs.map(x => x.data()));
    }, onError, onCompletion);
}
exports.listenCollection = listenCollection;
