"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelationshipType = exports.RelationshipObject = void 0;
var RelationshipObject;
(function (RelationshipObject) {
    RelationshipObject["ACCOUNT"] = "account";
    RelationshipObject["BOOKING"] = "booking";
    RelationshipObject["CONTACT"] = "contact";
    RelationshipObject["USERPROFILE"] = "userProfile";
    RelationshipObject["ORGANISATION"] = "organisation";
})(RelationshipObject = exports.RelationshipObject || (exports.RelationshipObject = {}));
var RelationshipType;
(function (RelationshipType) {
    RelationshipType["ASSISTS"] = "assists";
    RelationshipType["ASSISTS_TERRITORY"] = "assistsTerritory";
    RelationshipType["CAN_SEE_ACCOUNT"] = "canSeeAccount";
    RelationshipType["HAS_PARENT"] = "hasParent";
    RelationshipType["IS_IN_AREA"] = "isInArea";
    RelationshipType["IS_IN_MARKET"] = "isInMarket";
    RelationshipType["IS_IN_ROOT"] = "isInRoot";
    RelationshipType["IS_IN_TERRITORY"] = "isInTerritory";
    RelationshipType["IS_PHOTOGRAPHING_AT"] = "isPhotographingAt";
    RelationshipType["IS_WITH_ACCOUNT"] = "isWithAccount";
    RelationshipType["MANAGES"] = "manages";
    RelationshipType["MANAGES_AREA"] = "managesArea";
    RelationshipType["MANAGES_TERRITORY"] = "managesTerritory";
    RelationshipType["PORTAL_ACCESS_TO"] = "portalAccessTo";
    RelationshipType["USED_PHOTOGRAPHER"] = "usedPhotographer";
})(RelationshipType = exports.RelationshipType || (exports.RelationshipType = {}));
