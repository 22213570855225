"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isContact = void 0;
function isContact(contact) {
    const validator = [
        typeof (contact === null || contact === void 0 ? void 0 : contact.id) === 'string',
        typeof (contact === null || contact === void 0 ? void 0 : contact.email) === 'string'
    ];
    return validator.every(x => x);
}
exports.isContact = isContact;
