"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toVaultMeta = exports.fromVaultMeta = void 0;
function fromVaultMeta(meta) {
    var _a;
    return JSON.parse((_a = meta.metaString) !== null && _a !== void 0 ? _a : '');
}
exports.fromVaultMeta = fromVaultMeta;
function toVaultMeta(entry) {
    return { metaString: JSON.stringify(entry) };
}
exports.toVaultMeta = toVaultMeta;
