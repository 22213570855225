"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.firestoreDataConverter = void 0;
const model_1 = require("./model");
function firestoreDataConverter(ConcreteModel) {
    return {
        fromFirestore(snapshot, options) {
            return model_1.Model[model_1.fromConverter](ConcreteModel, snapshot);
        },
        toFirestore(modelObject) {
            if (modelObject === undefined) {
                throw new Error(`toFirestore - data for ${typeof ConcreteModel} is undefined`);
            }
            return modelObject;
        }
    };
}
exports.firestoreDataConverter = firestoreDataConverter;
