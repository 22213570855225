"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Model = exports.fromConverter = void 0;
const firestore_1 = require("firebase/firestore");
exports.fromConverter = Symbol('Model from Firestore Converter');
class Model {
    constructor(ref, data, metadata) {
        this._id = ref.id;
        this._ref = ref;
        this._metadata = metadata;
        const hiddenAndNonEnumerableProperty = {
            configurable: false,
            enumerable: false,
            writable: false
        };
        Object.defineProperties(this, {
            _id: hiddenAndNonEnumerableProperty,
            _ref: hiddenAndNonEnumerableProperty,
            _metadata: hiddenAndNonEnumerableProperty
        });
        if (data !== undefined) {
            Object.assign(this, data);
        }
    }
    get DocumentID() {
        return this._id;
    }
    get DocumentMetadata() {
        return this._metadata;
    }
    get DocumentReference() {
        return this._ref;
    }
    async updateDocument(data) {
        return await (0, firestore_1.updateDoc)(this._ref, data);
    }
    async deleteDocument() {
        return await (0, firestore_1.deleteDoc)(this._ref);
    }
    static [exports.fromConverter](ConcreteModel, snapshot) {
        return new ConcreteModel(snapshot.ref, snapshot.data(), snapshot.metadata);
    }
}
exports.Model = Model;
