"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.insertImageToExistingJob = exports.getPacksForJobSubType = exports.getNexusJobSubTypes = void 0;
const axios_1 = require("./axios");
const apiPath = '/nexus-api';
async function getNexusJobSubTypes() {
    const resp = await (0, axios_1.axios)({
        method: 'get',
        url: `${apiPath}/schools/jobsubtypes`
    });
    return resp.data;
}
exports.getNexusJobSubTypes = getNexusJobSubTypes;
async function getPacksForJobSubType(jobSubType) {
    const resp = await (0, axios_1.axios)({
        method: 'get',
        url: `${apiPath}/schools/jobsubtype/${jobSubType}/packs`
    });
    return resp.data;
}
exports.getPacksForJobSubType = getPacksForJobSubType;
async function insertImageToExistingJob(longRef) {
    const resp = await (0, axios_1.axios)({
        method: 'post',
        url: `${apiPath}/insertImageToExistingJob/${longRef}`
    });
    return resp.status === 200;
}
exports.insertImageToExistingJob = insertImageToExistingJob;
