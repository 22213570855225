"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shotModeToShotType = exports.isRefId = exports.isNexusId = exports.ShotType = exports.ImageStatus = void 0;
var ImageStatus;
(function (ImageStatus) {
    ImageStatus["FLAGGED"] = "FLAGGED";
    ImageStatus["REJECTED"] = "REJECTED";
    ImageStatus["NONE"] = "NONE";
})(ImageStatus = exports.ImageStatus || (exports.ImageStatus = {}));
var ShotType;
(function (ShotType) {
    ShotType[ShotType["PortraitMode"] = 1] = "PortraitMode";
    ShotType[ShotType["IndoorGroup"] = 2] = "IndoorGroup";
    ShotType[ShotType["OutdoorGroup"] = 3] = "OutdoorGroup"; // Only used in Studio ¯\_(ツ)_/¯
})(ShotType = exports.ShotType || (exports.ShotType = {}));
// eslint-disable-next-line
function isNexusId(obj) {
    return typeof (obj === null || obj === void 0 ? void 0 : obj.nexusId) === 'number' && typeof (obj === null || obj === void 0 ? void 0 : obj.market) === 'string';
}
exports.isNexusId = isNexusId;
// eslint-disable-next-line
function isRefId(obj) {
    return typeof (obj === null || obj === void 0 ? void 0 : obj.ref) === 'string' && typeof (obj === null || obj === void 0 ? void 0 : obj.market) === 'string';
}
exports.isRefId = isRefId;
const shotModeToShotType = (s) => {
    if (s === 'G') {
        return ShotType.IndoorGroup || ShotType.OutdoorGroup;
    }
    return ShotType.PortraitMode;
};
exports.shotModeToShotType = shotModeToShotType;
