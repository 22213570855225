"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataModelToDocument = exports.batchUpdate = exports.batchSetMerge = void 0;
const firestore_1 = require("firebase/firestore");
const lodash_1 = require("lodash");
/**
 * @deprecated Favour using add/update methods over set
 */
async function batchSetMerge(collection, elements) {
    return await batch(collection, elements, (batch, element) => {
        batch.set(typeof element.idOrRef === 'string'
            ? (0, firestore_1.doc)(collection, element.idOrRef)
            : element.idOrRef, element.data, { merge: true });
    });
}
exports.batchSetMerge = batchSetMerge;
async function batchUpdate(collection, elements) {
    return await batch(collection, elements, (batch, element) => {
        batch.update(typeof element.idOrRef === 'string'
            ? (0, firestore_1.doc)(collection, element.idOrRef)
            : element.idOrRef, element.data);
    });
}
exports.batchUpdate = batchUpdate;
async function batch(collection, elements, batchOp) {
    for (const chunkedElements of (0, lodash_1.chunk)(elements, 500)) {
        const batch = (0, firestore_1.writeBatch)(collection.firestore);
        for (const element of chunkedElements) {
            batchOp(batch, element);
        }
        await batch.commit();
    }
}
function dataModelToDocument(elements, idKey) {
    const results = [];
    for (const data of elements) {
        const maybeId = data[idKey];
        if (typeof maybeId !== 'string') {
            throw new Error(`"${idKey.toString()}" is not a valid Firestore ID! ${JSON.stringify(data)}`);
        }
        results.push({ idOrRef: maybeId, data });
    }
    return results;
}
exports.dataModelToDocument = dataModelToDocument;
