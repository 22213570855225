"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadRequest = void 0;
const accounts_models_1 = require("@ht-lib/accounts-models");
const firestore_1 = require("firebase/firestore");
const helpers_1 = require("../../helpers");
class DownloadRequestCollection extends helpers_1.Collection {
    byContextId(contextId) {
        return new helpers_1.QueryResult(this.collection, this.where('contextId', '==', contextId));
    }
    async add(settings, nameKey = 'ref') {
        console.log('Creating new Download request');
        const docRef = (0, firestore_1.doc)(this.collection);
        const id = docRef.id;
        const { userId, longRefs, zipDownloadName, prepSettings = null, classFolders, addEditedFolder, contextId } = settings;
        const add = {
            addEditedFolder,
            id,
            prepSettings,
            startTime: firestore_1.Timestamp.now(),
            zipDownloadName,
            classFolders,
            endTime: null,
            imageCount: longRefs.length,
            imageFinishedCount: 0,
            longRefs,
            nameKey,
            contextId: contextId || null,
            status: accounts_models_1.DownloadStatus.REQUESTED,
            userId
        };
        await (0, firestore_1.setDoc)(docRef, add);
        return await this.byId(id).single();
    }
}
class DownloadRequestDocument extends helpers_1.Model {
    constructor(ref, data, metadata) {
        if (typeof (data === null || data === void 0 ? void 0 : data.startTime) === 'number') {
            data.startTime = firestore_1.Timestamp.fromMillis(data.startTime);
        }
        if (typeof (data === null || data === void 0 ? void 0 : data.endTime) === 'number') {
            data.endTime = firestore_1.Timestamp.fromMillis(data.endTime);
        }
        super(ref, data, metadata);
    }
    async retry() {
        await this.updateDocument({ status: accounts_models_1.DownloadStatus.REQUESTED });
    }
}
class DownloadRequestWithData extends DownloadRequestDocument {
}
exports.DownloadRequest = new DownloadRequestCollection('downloadRequests', DownloadRequestDocument, DownloadRequestWithData);
