"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentAlreadyExistsError = exports.InvalidLongRefIdError = exports.DocumentNotExistError = void 0;
class DocumentNotExistError extends Error {
}
exports.DocumentNotExistError = DocumentNotExistError;
class InvalidLongRefIdError extends Error {
    constructor(longRef) {
        super(`${longRef} is not a valid longRef`);
    }
}
exports.InvalidLongRefIdError = InvalidLongRefIdError;
class DocumentAlreadyExistsError extends Error {
    constructor(id) {
        super(`Document ${id} already exists`);
    }
}
exports.DocumentAlreadyExistsError = DocumentAlreadyExistsError;
