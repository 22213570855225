"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMarketDescription = void 0;
const marketDescriptions = {
    G: 'Grads',
    S: 'Schools'
    // M: 'Military'
};
function getMarketDescription(m) {
    if (!m)
        return 'Schools';
    return marketDescriptions[m] || 'Schools';
}
exports.getMarketDescription = getMarketDescription;
