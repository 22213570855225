"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormPrint = exports.OrderFormPrinting = exports.Currency = void 0;
var Currency;
(function (Currency) {
    Currency["GBP"] = "GBP";
    Currency["EURO"] = "EURO";
})(Currency = exports.Currency || (exports.Currency = {}));
var OrderFormPrinting;
(function (OrderFormPrinting) {
    OrderFormPrinting["onTheDayPVS"] = "PVS";
    OrderFormPrinting["labPE"] = "PE";
    OrderFormPrinting["none"] = "None";
})(OrderFormPrinting = exports.OrderFormPrinting || (exports.OrderFormPrinting = {}));
var FormPrint;
(function (FormPrint) {
    FormPrint["ON_THE_DAY"] = "OnTheDay";
    FormPrint["LAB"] = "Lab";
    FormPrint["CONTACTLESS"] = "Contactless";
    FormPrint["NONE"] = "None";
})(FormPrint = exports.FormPrint || (exports.FormPrint = {}));
