"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userHasRoles = exports.UserRoles = void 0;
var UserRoles;
(function (UserRoles) {
    UserRoles["EXTERNAL_USER"] = "external-user";
    UserRoles["TEMPEST_STAFF"] = "tempest-staff";
    UserRoles["COMMISSION_ADMIN"] = "Commission Admin";
    UserRoles["DATA_TEAM"] = "Data Team";
    UserRoles["QC_REVIEWER"] = "Quality Control Reviewer";
    UserRoles["SOFTWARE_GODS"] = "Software Engineering";
    UserRoles["SALES_SUPPORT"] = "Sales Support";
    UserRoles["IMAGE_EDITING"] = "Image Editing";
    UserRoles["IMAGE_EDITING_ADMIN"] = "Image Editing Admin";
    UserRoles["PAYMENT_PROCESSING_ADMIN"] = "Payment Processing Admin";
    UserRoles["PAYMENT_PROCESSING"] = "Payment Processing";
    UserRoles["AREA_MANAGER"] = "Area Manager";
})(UserRoles = exports.UserRoles || (exports.UserRoles = {}));
function userHasRoles(userRoles, searchRoles) {
    var _a;
    const roles = Array.isArray(userRoles) ? userRoles : ((_a = userRoles.roles) !== null && _a !== void 0 ? _a : []);
    return roles.some(x => searchRoles.some(y => x === y));
}
exports.userHasRoles = userHasRoles;
