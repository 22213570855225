"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingPhotographerStatus = void 0;
var BookingPhotographerStatus;
(function (BookingPhotographerStatus) {
    BookingPhotographerStatus["ASSIGNED"] = "Assigned";
    BookingPhotographerStatus["COMPLETE"] = "Complete";
    // These two are defined in booking/salessupport
    BookingPhotographerStatus["NOTIFIED"] = "notified";
    BookingPhotographerStatus["CONFIRMED"] = "confirmed";
})(BookingPhotographerStatus = exports.BookingPhotographerStatus || (exports.BookingPhotographerStatus = {}));
