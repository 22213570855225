"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobStatusUserToAction = exports.jobStatusUserComplete = exports.NexusImportStatus = exports.PhotographerRole = exports.PhotographerJobStatus = exports.JobStatus = void 0;
const userProfile_1 = require("../userProfile");
var JobStatus;
(function (JobStatus) {
    JobStatus["DRAFT"] = "DRAFT";
    JobStatus["WAITING_FOR_PHOTOGRAPHERS"] = "WAITING FOR PHOTOGRAPHERS";
    JobStatus["AWAITING_APPROVAL"] = "AWAITING APPROVAL";
    JobStatus["UPLOAD_LOCKED"] = "UPLOAD LOCKED";
    JobStatus["IN_REVIEW"] = "IN REVIEW";
    JobStatus["AWAITING_COMMISSION"] = "AWAITING COMMISSION";
    JobStatus["REQUIRES_AIMS_NO"] = "REQUIRES AIMS NO";
    JobStatus["READY_FOR_QC"] = "READY FOR QC";
    JobStatus["QC_IN_PROGRESS"] = "QC IN PROGRESS";
    JobStatus["COMPLETE"] = "COMPLETE";
})(JobStatus = exports.JobStatus || (exports.JobStatus = {}));
var PhotographerJobStatus;
(function (PhotographerJobStatus) {
    PhotographerJobStatus["AWAITING"] = "AWAITING";
    PhotographerJobStatus["UPLOADING"] = "UPLOADING";
    PhotographerJobStatus["COMPLETE"] = "COMPLETE";
})(PhotographerJobStatus = exports.PhotographerJobStatus || (exports.PhotographerJobStatus = {}));
var PhotographerRole;
(function (PhotographerRole) {
    PhotographerRole["ABSENT"] = "absent";
    PhotographerRole["PHOTOGRAPHER"] = "photographer";
    PhotographerRole["ASSISTANT"] = "assistant";
})(PhotographerRole = exports.PhotographerRole || (exports.PhotographerRole = {}));
var NexusImportStatus;
(function (NexusImportStatus) {
    NexusImportStatus["AWAITING_REIMPORT"] = "Awaiting ReImport";
    NexusImportStatus["COMPLETED"] = "Completed";
    NexusImportStatus["PROCESSING"] = "Processing";
    NexusImportStatus["FAILED"] = "Failed";
})(NexusImportStatus = exports.NexusImportStatus || (exports.NexusImportStatus = {}));
function jobStatusUserComplete(roles) {
    const completeStatuses = [JobStatus.COMPLETE];
    if (roles.includes(userProfile_1.UserRoles.DATA_TEAM)) {
        completeStatuses.push(JobStatus.READY_FOR_QC, JobStatus.QC_IN_PROGRESS);
    }
    else if (roles.includes(userProfile_1.UserRoles.COMMISSION_ADMIN)) {
        completeStatuses.push(JobStatus.READY_FOR_QC, JobStatus.QC_IN_PROGRESS, JobStatus.REQUIRES_AIMS_NO);
    }
    return completeStatuses;
}
exports.jobStatusUserComplete = jobStatusUserComplete;
function jobStatusUserToAction(roles) {
    const QC_JOBS = [JobStatus.READY_FOR_QC, JobStatus.QC_IN_PROGRESS];
    const DATA_ENTRY_JOBS = [JobStatus.REQUIRES_AIMS_NO];
    const COMMISSION_JOBS = [JobStatus.AWAITING_COMMISSION];
    let visibleJobs = [];
    for (const role of roles) {
        switch (role) {
            case userProfile_1.UserRoles.SOFTWARE_GODS:
                return [...QC_JOBS, ...COMMISSION_JOBS, ...DATA_ENTRY_JOBS];
            case userProfile_1.UserRoles.QC_REVIEWER:
                visibleJobs = visibleJobs.concat(QC_JOBS);
                break;
            case userProfile_1.UserRoles.COMMISSION_ADMIN:
                visibleJobs = visibleJobs.concat(COMMISSION_JOBS);
                break;
            case userProfile_1.UserRoles.DATA_TEAM:
                visibleJobs = visibleJobs.concat(DATA_ENTRY_JOBS);
                break;
        }
    }
    return visibleJobs;
}
exports.jobStatusUserToAction = jobStatusUserToAction;
